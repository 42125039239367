// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
//import '@hotwired/turbo-rails'
import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import 'bootstrap-datepicker'
import bsCustomFileInput from 'bs-custom-file-input'
import "channels"
import 'trix'
import '@rails/actiontext'
import * as Heartbeat from './heartbeat'
import './browser_timezone_cookie'
import { escape, setupMutuallyExclusiveCheckboxesGroup } from './utils'
import "./controllers"

import "chartkick/chart.js"

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
window.startHeartbeat = Heartbeat.start

require("./dashboard")
global.bootbox = require("./bootbox.min.js")
global.toastr = require("toastr")

global.toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut",
  }

// skipConfirmation: https://bootboxjs.com/documentation.html#note-non-blocking
let skipConfirmation = false
Rails.confirm = (message, element) => {
  if (skipConfirmation) return true

  if (!('confirmHtml' in element.dataset)) {
    message = escape(message)
  }

  const onConfirm = () => {
    skipConfirmation = true
    element.click()
    skipConfirmation = false
  }

  bootbox.confirm({
    message: message,
    buttons: {
      confirm: {
        label: element.dataset.confirmLabel
      }
    },
    callback: (isConfirmed) => {
      if (isConfirmed) onConfirm()
    }
  })
}

const onReady = (fn) => {
  $(fn)
  $(document).on('ajax:complete', fn)
}

setupMutuallyExclusiveCheckboxesGroup()

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

onReady(() => {
  $('[data-tooltip="tooltip"]').tooltip()
  $("#turbo-modal").on("shown.bs.modal", () => {
    $('[data-tooltip="tooltip"]').tooltip()
    bsCustomFileInput.init()
  })
})

const updateTooltipTitle = (element, title, options = {}) => {
  const $element = $(element)
  const originalTitle = $element.attr('title')
  const dataOriginalTitle = $element.attr('data-original-title')
  const tooltipId = $element.attr('aria-describedby')
  const tooltipShown = tooltipId !== undefined && $(`#${tooltipId}`).length !== 0
  $element.attr('title', title)
  $element.attr('data-original-title', title)
  if (tooltipShown) {
    $element.tooltip('update')
    $element.tooltip('show')
  }
  if (options.flash) {
    $element.attr('title', originalTitle)
    $element.attr('data-original-title', dataOriginalTitle)
  }
}

$(document).on('click', '[data-copy-to-clipboard]', (event) => {
  const $button = $(event.currentTarget)
  const $target = $($button.data('copy-to-clipboard'))
  const value = $target.data('unmasked-value') ?? $target.val()
  navigator.clipboard.writeText(value)
  updateTooltipTitle(event.currentTarget, 'Copied!', { flash: true })
})

$(document).on('click', '[data-copy-to-clipboard-value]', (event) => {
  navigator.clipboard.writeText(event.currentTarget.dataset.copyToClipboardValue)
  updateTooltipTitle(event.currentTarget, 'Copied!', { flash: true })
})

$(document).on('change', '[data-file-input-preview]', (event) => {
  const input = event.currentTarget
  const [file] = input.files
  $(input.dataset.fileInputPreview, document).each((_index, preview) => {
    if (file === undefined) {
      preview.src = preview.dataset.originalSrc ?? ''
      return
    }

    preview.dataset.originalSrc ??= preview.src
    const url = URL.createObjectURL(file)
    preview.src = url
    preview.onload = () => URL.revokeObjectURL(url)
  })
})

$(document).on('click', '[data-toggle-masked-value]', (event) => {
  const $button = $(event.currentTarget)
  const $input = $($button.data('toggle-masked-value'))
  const $icon = $button.find('.fa')
  const value = $input.data('unmasked-value') ?? ''
  const revealed = $icon.hasClass('fa-eye')
  if (revealed) {
    $input.val(value)
    updateTooltipTitle(event.currentTarget, 'Hide')
  } else {
    $input.val('•'.repeat(value.length))
    updateTooltipTitle(event.currentTarget, 'Reveal')
  }
  $icon.toggleClass('fa-eye fa-eye-slash')
})

// Partner form
$(document).on('change', '[name="partner[logo]"]', (event) => {
  $('[name="partner[remove_logo]"]:checkbox').prop('disabled', event.currentTarget.value !== '')
})

$(() => bsCustomFileInput.init())
